<template>
  <div>
    <div
      class="modal fade"
      id="modalCopyMdtDisease"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalCopyMdtDisease"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <h5
            class="p-3 fs-18 text-black"
            id="modalSendCaseToOrgLabel"
          >Tạo bản sao cho ca bệnh đăng ký hội chẩn</h5>
          <div class="p-3">
            <div class="mb-3">
              <label for="room" class="fs-16 mb-1 text-black">
                {{ $t('multidisciplinary_board.lbl_select_mdt_room')
                }}
              </label>
              <select
                :disabled="mdt_rooms.length <= 0"
                id="room"
                class="form-select bg-white text-black border"
                v-model="form.mdt_room_id"
              >
                <option :value="null">{{ $t('multidisciplinary_board.lbl_select_room') }}</option>
                <option v-for="r in mdt_rooms" :value="r.id" :key="r.id + 'room'">{{ r.name }}</option>
              </select>
            </div>
            <div class="mb-3 flex items-center justify-between">
              <label for="date" class="fs-16 mb-1 whitespace-nowrap text-black">
                {{
                $t('multidisciplinary_board.lbl_select_mdt_date') }}
              </label>
              <input
                id="date "
                type="date"
                v-model="form.date"
                class="form-control bg-white border w-52"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-pri text-white"
              @click="submit"
              :disabled="loading"
            >Tạo</button>
            <button type="button" class="btn" @click="handleCloseModal" :disabled="loading">Huỷ</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import moment from 'moment'
import { get } from 'lodash'
import vSelect from 'vue-select'

export default {
  name: 'ModalCopyMdtDisease',
  props: ['mdtDiseaseId', 'clinicId'],
  components: { vSelect },
  data () {
    return {
      mdt_rooms: [],
      form: {
        mdt_room_id: null,
        date: moment().format('YYYY-MM-DD')
      },
      loading: false
    }
  },
  methods: {
    async getMdtRooms () {
      if (!this.clinicId) return

      const params = {
        page: 1,
        limit: 200,
        clinic_id: this.clinicId
      }

      const response = await this.$rf
        .getRequest('DoctorRequest')
        .getMdtRooms(params)
      this.mdt_rooms = response.data.data
    },

    handleCloseModal () {
      window.$('#modalCopyMdtDisease').modal('hide')
      this.resetForm()
    },
    async submit () {
      if (!this.form.mdt_room_id) {
        alert(this.$t('multidisciplinary_board.lbl_select_room_alert'))
        return
      }
      if (!this.form.date) {
        alert(this.$t('multidisciplinary_board.lbl_select_date_alert'))
        return
      }
      if (!this.mdtDiseaseId) {
        alert('Vui lòng chọn ca bệnh trước')
        return
      }

      this.loading = true
      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .postCopyMdtRoomDisease({
            mdt_room_diseases_ids: [this.mdtDiseaseId],
            mdt_room_id: this.form.mdt_room_id,
            date: window.moment(this.form.date).format('YYYY-MM-DD')
          })
        this.resetForm()
        this.handleCloseModal()
        this.$emit('onCopySuccess', get(response, 'data[0]'))
      } finally {
        this.loading = false
      }
    },
    resetForm () {
      this.form = {
        mdt_room_id: null,
        date: moment().format('YYYY-MM-DD')
      }
    },
    openModal (show) {
      if (show) {
        this.getMdtRooms()
      }
      window.$('#modalCopyMdtDisease').modal(show ? 'show' : 'hide')
    }
  }
}
</script>
  
<style scoped></style>