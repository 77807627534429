<template>
  <div v-loading="loading" class="p-3 radius-10 bg-white">
    <MdtDiseaseForm
      @submit="addMdtDiseases"
      @refresh="()=>{}"
      @onClose="onClose"
      :isUpdate="true"
      :mdt_disease="mdt_disease"
      :loading="loading || isFetchingPdf"
      :room="room"
    />

    <div id="canvas-clone" class="hidden"></div>

    <TTTQMTemplate
      :form="operation"
      :mdt_disease="mdt_disease"
      :room_info="room"
      :save_pdf="save_pdf"
      @onSubmiting="handleSubmitPDF"
    />
  </div>
</template>

<script>
import MdtDiseaseForm from '../../components/HoiChan/MdtDiseaseForm.vue'
import TTTQMTemplate from '../../templates/HoiChan/TTTQMTemplate.vue'
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'
import { DOCUMENT_SIGN_TYPES } from '../../utils/constants'
import { mapState } from 'vuex'

export default {
  name: 'UpdateMdtDisease',
  components: { MdtDiseaseForm, TTTQMTemplate },
  data () {
    return {
      room: null,
      loading: false,
      mdt_disease: null,
      operation: {},
      save_pdf: 0,
      detailID: null,
      isFetchingPdf: false
    }
  },
  computed: {
    ...mapState({
      mdtRoomStore: state => state.mdtStore.mdtRoomDetail || {}
    })
  },
  mounted () {
    this.showMdtDiseases()
  },
  methods: {
    onClose () {
      this.$router.push({
        name: 'MdtDiseaseDetail',
        params: { id: this.$route.params.id }
      })
    },
    async showMdtDiseases () {
      let self = this

      await self.$rf
        .getRequest('DoctorRequest')
        .showMdtDiseases(this.$route.params.id)
        .then(r => {
          self.mdt_disease = r.data

          if (r.data?.operation) {
            const diseaseCurrentLangData = r.data?.multilang?.find(
              item => item.lang === 1
            )

            const { ...newOperation } = r.data.operation
            this.operation = {
              ...newOperation,
              age: this.getAges(r.data?.patient_birthday) || null,
              code: newOperation.code || '',
              diagnostic:
                (diseaseCurrentLangData &&
                  diseaseCurrentLangData?.patient_diagnostic) ||
                r.data.patient_diagnostic ||
                '',
              mdt_time: newOperation.mdt_time
                ? appUtils.formatInputDateTime(newOperation.mdt_time)
                : appUtils.formatInputDateTime(this.room.start_time),
              surgery_date:
                newOperation.surgery_date ||
                window.moment().format('YYYY-MM-DD'),
              medical_history: newOperation.medical_history || '',
              conclusion: newOperation.conclusion || '',
              preside: newOperation?.preside || r.data?.preside || '',
              secretary: newOperation?.doctor_name || r.data.doctor?.name || '',
              members: newOperation?.members || '',
              treatment_time_start:
                appUtils.formatInputDateTime(
                  newOperation?.treatment_time_start
                ) ||
                appUtils.formatInputDateTime(r.data?.treatment_time_start) ||
                '',
              treatment_time_end:
                appUtils.formatInputDateTime(
                  newOperation?.treatment_time_end
                ) ||
                appUtils.formatInputDateTime(r.data?.treatment_time_end) ||
                ''
            }
          }
          self.showMdtRoom(r.data.mdt_room_id)
        })
    },
    async addMdtDiseases (form, alias_params, patient_diagnostic) {
      let self = this
      self.isFetchingPdf = true
      try {
        this.loading = true
        form.set('mdt_room_id', this.mdt_disease.mdt_room_id)
        form.set('status', this.mdt_disease.status)

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .updateMdtDiseases(this.$route.params.id, form)

        if (res.data) {
          if (this.operation.id && patient_diagnostic) {
            this.operation.diagnostic = patient_diagnostic

            setTimeout(() => {
              this.save_pdf++
            }, 500)
            this.detailID = res.data.id
          } else {
            this.isFetchingPdf = false
            this.loading = false

            this.goToDetail(res.data.id)
          }
          if (alias_params) {
            await this.setAliasPerson({ mtdDiseases: res.data, alias_params })
          }
        } else {
          this.loading = false
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async setAliasPerson ({ mtdDiseases, alias_params }) {
      if (!mtdDiseases?.id) {
        this.$toast.open({
          message: `mdt_room_disease_id no empty`,
          type: 'error'
        })
        return
      }

      try {
        const { alias_id, ...alias_form } = alias_params

        const params = {
          ...alias_form,
          mdt_room_disease_id: mtdDiseases.id,
          medical_record_id: null,
          person_id: Number(mtdDiseases?.person_id) || null
        }

        if (alias_id) {
          await this.$rf
            .getRequest('DoctorRequest')
            .updateAliasPerson(alias_id, params)
            .then(res => {
              // console.log(res)
            })
        } else {
          await this.$rf
            .getRequest('DoctorRequest')
            .setAliasPerson(params)
            .then(res => {
              // console.log(res)
            })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async showMdtRoom (id) {
      let self = this
      if (self.mdtRoomStore?.id === Number(id)) {
        const clonedMdtRoom = { ...self.mdtRoomStore }

        self.room = clonedMdtRoom

        return clonedMdtRoom
      } else {
        self.loading = true
        self.$rf
          .getRequest('DoctorRequest')
          .getMDTRoomDetail(id)
          .then(r => {
            self.room = r.data
            this.$store.commit('mdtStore/setMdtRoomDetail', r.data)
          })
          .catch(e => {
            console.log(e)
            self.$router.push({ name: 'ListMdt' })
          })
          .finally(() => {
            self.loading = false
          })
      }
    },
    goToDetail (id) {
      this.$router.push({ name: 'MdtDiseaseDetail', params: { id: id } })
    },
    async handleSubmitPDF (base64) {
      await this.savePdfToS3(base64, this.operation.id).finally(() => {
        this.isFetchingPdf = false
        this.goToDetail(this.detailID)
      })
    },
    getAges (date) {
      return mdtUtils.getAges(new Date(date))
    },
    getGender (data) {
      return mdtUtils.getGender(data)
    },
    async savePdfToS3 (base64, operationId = this.operation?.id) {
      if (!base64 || !operationId) return

      try {
        this.loading = true
        const payload = {
          content: base64,
          fileName:
            `${appUtils.convertSlug(
              `${this.$t('TTTQM')} ${operationId} ${window
                .moment(this.operation?.created_at)
                .format('YYYY-MM-DD')}`
            )}_` || 'operation_',
          path: 'room_disease_operation',
          type: DOCUMENT_SIGN_TYPES.CODE.DISEASE_OPERATION,
          type_id: operationId,
          uuid: ''
        }

        await this.$rf.getRequest('DoctorRequest').printDocument(payload)
      } catch (error) {
        console.log(error)
        // this.loading = false
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-control {
  background: #ffffff;
  /* Neutral/300 */

  border: 1.5px solid #b4b4c4;
  border-radius: 10px;
  color: #616673;
}

label {
  margin-bottom: 10px;
}

.btn-save {
  height: 50px;
  width: 190px;
  border-radius: 8px;
  padding: 8px, 16px, 8px, 16px;
}
</style>
