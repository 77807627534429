<template>
  <div>
    <div
      class="modal fade"
      id="modalSendCaseToOrg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalSendCaseToOrgLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="d-flex justify-content-between p-3">
            <h5
              class="modal-title"
              id="modalSendCaseToOrgLabel"
            >{{$t('multidisciplinary_board.lbl_submit_org')}}</h5>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="robo-18-500">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label
                for="room"
                class="required fs-16 mb-1"
              >{{$t('multidisciplinary_board.lbl_mdt_room')}}</label>
              <select id="room" class="form-select bg-white border" v-model="form.public_mdt_id">
                <option :value="null">{{$t('multidisciplinary_board.lbl_select_room')}}</option>
                <option v-for="r in public_mdts" :value="r.id" :key="r.id + 'room'">{{r.name}}</option>
              </select>
            </div>
            <div class="mb-3">
              <label
                for="date"
                class="required fs-16 mb-1"
              >{{$t('multidisciplinary_board.lbl_mdt_date')}}</label>
              <input id="date" type="date" v-model="form.date" class="form-control bg-white border" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-pri text-white"
              @click="submit"
              :disabled="loading"
            >{{$t('multidisciplinary_board.btn_send')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ModalSendCaseToOrg',
  props: ['mdtDiseaseIds'],
  data () {
    return {
      public_mdts: [],
      form: {
        public_mdt_id: null,
        date: moment().format('YYYY-MM-DD')
      },
      loading: false
    }
  },

  mounted () {
    // this.getPublicMtdRooms()
  },
  methods: {
    async getPublicMtdRooms () {
      const params = {
        organization_id: this.$globalClinic?.org_id,
        page: 1,
        limit: 200
      }
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getPublicMtdRooms(params)
      this.public_mdts = res.data.data
    },
    close () {
      window.$('#modalSendCaseToOrg').modal('hide')
    },
    openModal () {
      this.getPublicMtdRooms()

      window.$('#modalSendCaseToOrg').modal('show')
    },
    async submit () {
      if (!this.form.public_mdt_id) {
        alert(this.$t('multidisciplinary_board.lbl_select_room_alert'))
        return
      }
      if (!this.form.date) {
        alert(this.$t('multidisciplinary_board.lbl_select_date_alert'))
        return
      }
      if (!this.mdtDiseaseIds || !this.mdtDiseaseIds.length) {
        alert('Vui lòng chọn ca bệnh trước')
        return
      }
      this.loading = true
      try {
        await this.$rf.getRequest('DoctorRequest').sendCasesToOrg({
          mdt_room_diseases_ids: this.mdtDiseaseIds,
          public_mdt_id: this.form.public_mdt_id,
          date: moment(this.form.date, 'YYYY-MM-DD').toDate()
        })
        this.resetForm()
        this.close()
        this.$emit('sent')
      } finally {
        this.loading = false
      }
    },
    resetForm () {
      this.form = {
        public_mdt_id: null,
        date: moment().format('YYYY-MM-DD')
      }
    }
  }
}
</script>

<style scoped>
</style>
